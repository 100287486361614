.spinner {
  display: inline-block;
  width: 90px;
  height: 90px;
}
.spinner:after {
  content: ' ';
  display: block;
  width: 55px;
  height: 55px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid teal;
  border-color: teal #adadad #adadad #adadad;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form {
  position: relative;
  padding: .5rem 1rem;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.control input,
.control textarea {
  font: inherit;
  padding: 0.35rem;
  border-radius: 3px;
  border: 1px solid #041b1b;
  display: block;
  width: 100%;
  font-size: 1.25rem;
}

.control input:focus,
.control textarea:focus {
  outline-color: #076161;
}

.newquote {
  width: 100%; 
  max-width: 25rem; 
  margin: 0 auto;
}

.actions {
  text-align: right;
}

@media (max-width: 576px) {
  .form {
    position: relative;
    padding: .5rem 0rem;
  }
}
.comment {
  border-top: 2px solid #b2d4d4;
  padding-top: 0.8rem;
}

.form {
  background-color: #162b2b;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 1.5rem;
  position: relative;
  text-align: center;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.control {
  margin-bottom: 0.8rem;
}

.control label {
  font-weight: bold;
  display: block;
  width: 20rem;
  margin: 0 auto;
  text-align: start;
}

.control textarea {
  font: inherit;
  margin: auto;
  margin-bottom: 0.8rem;
  padding: 0.35rem;
  border-radius: 3px;
  border: 1px solid #c1d1d1;
  width: 100%;
  max-width: 20rem;
  display: block;
  font-size: 1rem;
}

.control textarea:focus {
  outline-color: #076161;
}

.control input {
  font: inherit;
  margin: auto;
  max-width: 20rem;
  width: 100%;
  padding: 0.35rem;
  border-radius: 3px;
  border: 1px solid #c1d1d1;
}

.actions button {
  padding: 0.25rem 2.1rem;
}

@media (max-width: 576px) {
  .form {
    padding: 1rem;
  }
}

.quote {
  background-color: #162b2b;
  border-radius: 6px;
  padding: 2rem;
  margin: 3rem auto;
  max-width: 50rem;
}

.quote p {
  font-size: 1.8rem;
}

.quote figcaption {
  font-size: 1.3rem;
  text-align: right;
  color: #a2a2a2;
}

.quote figcaption:last-of-type { 
  margin-top: 1rem;
  font-size: .9rem;
}

@media (max-width: 576px) {
  .quote {
    padding: 1.5rem;
  }
}

@media (max-width: 767px) {
  .quote p {
    font-size: 1.4rem;
  }
}

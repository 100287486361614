.item {
  background-color: #162b2b;
  border-radius: 5px;
  text-align: start;
  margin: 1rem 0;
  font-size: 1.25rem;
  padding: 1rem 1.5rem;
}

.comment-author {
  font-family: 'Arial', sans-serif;
  font-size: 13px;
  margin-bottom: 3px;
}

.comment-date {
  color: grey;
  font-size: 13px;
  font-family: 'Arial', sans-serif;
  margin-top: 0;
}
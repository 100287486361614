h4 {
  margin-bottom: 0;
}

.nav {
  font-size: 19px;
  margin-top: 0.35rem;
}

.nav a {
  text-decoration: none;
  color: #e6fcfc;
}

.nav a:first-of-type {
  margin-right: 1rem;
}

.nav a:hover,
.nav a:active,
.nav a.active {
  color: #299b9b;
}

@media (max-width: 767px) {
  .nav {
    font-size: 15px;
    margin-top: .5rem;
  }
}